import {
  // Autocomplete,
  Box,
  // Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useRef, useState } from "react";
import StyledRadio from "../../../global/FormComponents/StyledRadio";
import useClasses from "../../../hooks/useClasses";
import { StyledButton, StyledText } from "../../../theme/commonStyles";
import { styles } from "./form.styles";
import InfoIcon from "@mui/icons-material/Info";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const number_of_working_days = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
];

const working_hours_daily = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },
];

const retailing_hours_daily_arr = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 1.5,
    label: "1.5",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 2.5,
    label: "2.5",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 3.5,
    label: "3.5",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 4.5,
    label: "4.5",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 5.5,
    label: "5.5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 6.5,
    label: "6.5",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 7.5,
    label: "7.5",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 8.5,
    label: "8.5",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 9.5,
    label: "9.5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 10.5,
    label: "10.5",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 11.5,
    label: "11.5",
  },
  {
    value: 12,
    label: "12",
  },
];

const RoutePlanForm = ({
  routePlanFormData,
  setRoutePlanFormData,
  errors2,
  employeeFile,
  setEmployeeFile,
}) => {
  const classes = useClasses(styles);
  const [retailing_hours_daily, set_retailing_hours_daily] = useState([
    ...retailing_hours_daily_arr,
  ]);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState();
  const handleFileUpload = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    setEmployeeFile(file);
    setLoading(false);
  };

  useEffect(() => {
    const temp = [...retailing_hours_daily_arr];
    const data = temp.splice(
      0,
      routePlanFormData.data_config.working_hours_per_day * 2 - 1
    );
    set_retailing_hours_daily(data);
  }, [routePlanFormData.data_config.working_hours_per_day]);

  const excelDownloadHandler = () => {
    const data = [
      {
        EmployeeId: 1201958,
        HomeLatitude: 26.74757,
        HomeLongitude: 83.3687,
      },
    ];
    if (data.length > 0) {
      /* create a new blank workbook */
      var wb = XLSX.utils.book_new();

      /* create a worksheet for person details */
      var wsEmployeeDetails = XLSX.utils.json_to_sheet(data);

      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, wsEmployeeDetails, "Details");

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
      const data1 = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data1, "SampleEmployeeDetails.csv");
    }
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <Stack spacing={5}>
            <Stack>
              <TextField
                type="number"
                className={classes.textField}
                label="Company Id"
                value={routePlanFormData.company_id}
                onChange={(e) => {
                  setRoutePlanFormData({
                    ...routePlanFormData,
                    company_id: +e.target.value,
                  });
                }}
                variant="standard"
                error={Boolean(errors2.company_id)}
                helperText={errors2.company_id}></TextField>
            </Stack>
            <Stack spacing={2}>
              <StyledText variant="TextLeftBlackHighBody1Regular">
                First Store Visit
              </StyledText>
              <TimePicker
                className={classes.timePickerField}
                label={null}
                value={routePlanFormData.data_config?.day_start_time}
                onChange={(newValue) => {
                  setRoutePlanFormData({
                    ...routePlanFormData,
                    data_config: {
                      ...routePlanFormData.data_config,
                      day_start_time: newValue,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField variant="filled" {...params} />
                )}
              />
            </Stack>
            <Stack direction="row" spacing={4}>
              <TextField
                className={classes.textField}
                select
                label={
                  <>
                    <span className="star">*</span> Working hours daily
                  </>
                }
                value={
                  routePlanFormData.data_config?.working_hours_per_day || ""
                }
                onChange={(e) => {
                  setRoutePlanFormData({
                    ...routePlanFormData,
                    data_config: {
                      ...routePlanFormData.data_config,
                      working_hours_per_day: e.target.value,
                    },
                  });
                }}
                variant="standard">
                {working_hours_daily.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                disabled={!routePlanFormData.data_config?.working_hours_per_day}
                className={classes.textField}
                select
                label={
                  <>
                    <span className="star">*</span> Retailing hours daily
                  </>
                }
                variant="standard"
                value={
                  routePlanFormData.data_config?.retailing_hours_per_day || ""
                }
                onChange={(e) => {
                  setRoutePlanFormData({
                    ...routePlanFormData,
                    data_config: {
                      ...routePlanFormData.data_config,
                      retailing_hours_per_day: e.target.value,
                    },
                  });
                }}>
                {retailing_hours_daily.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Stack>
            <TextField
              className={classes.textField}
              select
              label={
                <>
                  <span className="star">*</span> Working days weekly
                </>
              }
              variant="standard"
              value={routePlanFormData.data_config?.number_of_week_days || ""}
              onChange={(e) => {
                setRoutePlanFormData({
                  ...routePlanFormData,
                  data_config: {
                    ...routePlanFormData.data_config,
                    number_of_week_days: e.target.value,
                  },
                });
              }}>
              {number_of_working_days.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Stack>
              <Stack direction="row" alignItems="center">
                <StyledText variant="TextLeftBlackHighBody1Regular">
                  Route Format
                </StyledText>
                <Tooltip
                  title="WIDTH  logic will prioritize visits to as many stores as possible. DEPTH logic will prioritise visits to stores with maximum revenue potential."
                  placement="right-start">
                  <IconButton size="small">
                    <InfoIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                </Tooltip>
              </Stack>
              <FormControl sx={{ py: 1 }}>
                <RadioGroup
                  row
                  value={routePlanFormData.depth_width}
                  onChange={(e) => {
                    setRoutePlanFormData({
                      ...routePlanFormData,
                      depth_width: +e.target.value,
                    });
                  }}>
                  <FormControlLabel
                    className={classes.radioLabel}
                    value={1}
                    control={<StyledRadio />}
                    label="Width"
                  />
                  <FormControlLabel
                    sx={{ ml: 8 }}
                    className={classes.radioLabel}
                    value={0}
                    control={<StyledRadio />}
                    label="Depth"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <TextField
              type="number"
              className={classes.textField}
              label={
                <>
                  <span className="star">*</span> Travel Per Day(Kms)
                </>
              }
              variant="standard"
              value={routePlanFormData.data_config?.max_distance_per_day || ""}
              onChange={(e) =>
                setRoutePlanFormData({
                  ...routePlanFormData,
                  data_config: {
                    ...routePlanFormData.data_config,
                    max_distance_per_day: parseInt(e.target.value),
                  },
                })
              }
              error={Boolean(errors2.data_config.max_distance_per_day)}
              helperText={errors2.data_config.max_distance_per_day}></TextField>
            <Stack direction="column">
              {/* <Autocomplete
                disabled={employeeFile}
                className={classes.textField}
                limitTags={2}
                multiple
                options={[]}
                freeSolo
                value={routePlanFormData.employee_ids}
                onChange={(event, value) => {
                  setRoutePlanFormData({
                    ...routePlanFormData,
                    employee_ids: value,
                  });
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      size="small"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="number"
                    placeholder="Search To Add"
                    label={
                      <>
                        <span className="star">*</span> Employee Name
                      </>
                    }
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-root": {
                        p: 1,
                        pb: 0,
                      },
                    }}
                    error={Boolean(errors2.employee_ids)}
                    helperText={errors2.employee_ids}
                  />
                )}
              /> */}

              {/* <StyledText
                fs={16}
                variant="TextCenterBlackLowBodyLight"
                ml={5}
                mt={2}>
                or
              </StyledText> */}
              {Boolean(errors2.employee_ids) && (
                <StyledText variant="TextLeftRedHighBody1Regular" mb={1}>
                  {errors2.employee_ids}
                </StyledText>
              )}
              <input
                ref={fileInputRef}
                onChange={handleFileUpload}
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: "none" }}
              />
              <Stack
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-start">
                <StyledButton
                  loading={loading}
                  onClick={() => {
                    if (employeeFile) {
                      setEmployeeFile(null);
                      fileInputRef.current.value = "";
                    } else {
                      fileInputRef.current.click();
                    }
                  }}>
                  <StyledText
                    fs={16}
                    variant="TextLeftFA-BlueHighOverlineAll-CapsRegular">
                    {employeeFile ? "Clear" : "Upload File"}
                  </StyledText>
                </StyledButton>
                {!employeeFile && (
                  <Box>
                    <Link
                      component="button"
                      variant="caption"
                      onClick={(e) => {
                        e.preventDefault();
                        excelDownloadHandler();
                      }}>
                      Download Sample File
                    </Link>
                    <Tooltip
                      title={`EmployeeId: (integer) - Unique id for sales rep,
                    HomeLatitude: (float),
                    HomeLongitude: (float),
                    `}
                      placement="right-start">
                      <IconButton size="small" sx={{ ml: 0.5 }}>
                        <InfoIcon sx={{ fontSize: 16, color: "#005486" }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

                {employeeFile && (
                  <StyledText fs={14} variant="TextCenterBlackLowBodyLight">
                    {employeeFile?.name}
                  </StyledText>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </>
  );
};

export default RoutePlanForm;
