import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";

export const COLORS = {
  "background-light": "#fff",
  "background-dark": "#f5f5f5",
  "primary-light": "#e5f8ff",
  "secondary-fa-gray": "#5b666e",
  "primary-dark": "#005486",
  "primary-fa-blue": "#0097cd",
  "secondary-dark": "#282b2d",
  "black-high": "rgba(0, 0, 0, 0.87)",
  "secondary-light": "#eee",
  "black-low": "rgba(0, 0, 0, 0.3)",
  "black-medium": "rgba(0, 0, 0, 0.6)",
  "white-medium": "rgba(255, 255, 255, 0.6)",
  "metro-dark-green": "#307146",
  "metro-magenta": "#ec4497",
  "metro-light-green": "#4ba428",
  "metro-purple": "#a139a7",
  "metro-cyan": "#49acaa",
  "white-low": "rgba(255, 255, 255, 0.3)",
  "metro-yellow": "#f9c446",
  "metro-orange": "#da5330",
  "highlight-light-green-tint": "rgba(75, 164, 40, 0.2)",
  "highlight-yellow-tint": "rgba(249, 196, 70, 0.2)",
  "metro-red": "#ed3833",
  "highlight-red-tint": "rgba(237, 56, 51, 0.2)",
  "shape-nav-bar-elements-selected": "#e1f9ff",
  "highlight-gray-tint": "#d8d8d8",
  "shape-scrim-gray": "rgba(0, 0, 0, 0.4)",
  "shape-table-cell-error": "rgba(237, 56, 51, 0.1)",
  "shape-button-primary-2": "rgba(255, 255, 255, 0.1)",
  "shape-action-affirmative-ripple": "rgba(255, 255, 255, 0.15)",
  "background-medium": "#fafafa",
};

export const SPACING = {
  "spacing-xs": "4px",
  "spacing-s": "8px",
  "spacing-m": "12px",
};

export const flexStyles = (dir, ai = "", jc = "") => ({
  display: "flex",
  flexDirection: dir === "col" ? "column" : "row",
  alignItems: ai === "fs" ? "flex-start" : ai === "fe" ? "flex-end" : "center",
  justifyContent:
    jc === "fs"
      ? "flex-start"
      : jc === "fe"
      ? "flex-end"
      : jc === "se"
      ? "space-evenly"
      : jc === "sb"
      ? "space-between"
      : "center",
});

export const StyledText = styled(Typography)`
  ${({ fs }) =>
    fs &&
    css`
      font-size: ${fs}px;
    `}
  ${({ color }) =>
    color &&
    css`
      color: ${color.typeOf === "string" &&
      (color.includes("#") ? color : COLORS[color])};
    `}
`;

export const StyledButton = styled(LoadingButton)`
  height: 32px;
  ${({ bg }) =>
    bg &&
    css`
      background-color: ${bg.includes("#") ? bg : COLORS[bg]};
      &:hover {
        background-color: ${bg.includes("#") ? bg : COLORS[bg]};
        opacity: 0.8;
      }
    `}
  ${({ flexcenter }) =>
    flexcenter === "true" &&
    css`
      align-items: center;
      justify-content: center;
    `};
  ${({ stop_hover }) =>
    stop_hover === "true" &&
    css`
      &:hover {
        opacity: 1;
        cursor: unset;
      }
    `};
  text-transform: none;
  border-radius: 5px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  &.Mui-disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: auto;
  }
`;
