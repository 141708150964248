import { Box, Stack } from "@mui/material";
import React from "react";
import useClasses from "../../hooks/useClasses";
import { StyledText } from "../../theme/commonStyles";
import { styles } from "./formProgressBar.styles";

const FormProgressBar = ({ currentValue = 0, steps = [], setValue }) => {
  const classes = useClasses(styles);
  const firstStepUnlocked =
    JSON.parse(sessionStorage.getItem("__dataPrepare__")) ||
    JSON.parse(sessionStorage.getItem("__isDataUpload__"));

  const secondStepUnlocked = JSON.parse(
    sessionStorage.getItem("__routePlan__")
  );
  const handler = (i) => {
    if (i === 0) {
      if (firstStepUnlocked) {
        setValue(0);
      }
    }
    if (i === 1) {
      if (firstStepUnlocked) {
        setValue(1);
      }
    }
    if (i === 2) {
      if (secondStepUnlocked) {
        setValue(2);
      }
    }
  };
  return (
    <Stack direction="row" className={classes.progressWrapper}>
      {steps.map((step, i) => (
        <Box
          sx={{
            cursor:
              i === 0
                ? firstStepUnlocked
                  ? "pointer"
                  : "not-allowed"
                : i === 1
                ? firstStepUnlocked
                  ? "pointer"
                  : "not-allowed"
                : i === 2
                ? secondStepUnlocked
                  ? "pointer"
                  : "not-allowed"
                : "",
          }}
          onClick={() => handler(i)}
          key={i}
          className={[
            classes.progressItemWrapper,
            currentValue === step.value && "highlight",
          ]}>
          {i !== steps.length - 1 && (
            <Box
              className={[
                classes.shape,
                currentValue === step.value && "highlight",
              ]}
            />
          )}
          <StyledText
            fs={12}
            textTransform="uppercase"
            variant={
              currentValue === step.value
                ? "TextLeftFA-BlueHighOverlineAll-CapsRegular"
                : "TextLeftBlackMediumOverlineAll-CapsLight"
            }>
            {step.title}
          </StyledText>
        </Box>
      ))}
    </Stack>
  );
};

export default FormProgressBar;
