import { COLORS } from "../../theme/commonStyles";

export const styles = (theme) => ({
  progressWrapper: {
    border: `1px solid ${theme.colors["highlight-gray-tint"]}`,
  },
  progressItemWrapper: {
    flex: 1,
    padding: "0.5rem 24px",
    position: "relative",
    // clipPath: "polygon(0% 0%, 96% 0%, 100% 50%, 96% 100%, 0% 100%)",
    "&.highlight": {
      background: COLORS["primary-light"],
    },
    "&:nth-last-of-type(-n+2)": {
      paddingLeft: "2rem",
    },
    boxShadow: "none",
    borderRadius: 0,
  },
  shape: {
    height: "30px",
    width: "30px",
    border: `1px solid ${theme.colors["highlight-gray-tint"]}`,
    borderLeft: "none",
    borderBottom: "none",
    position: "absolute",
    top: "5px",
    right: "-15px",
    transform: "rotate(45deg)",
    background: COLORS["background-light"],
    "&.highlight": {
      background: COLORS["primary-light"],
    },
    zIndex: 1,
  },
});
