import { toast } from "react-toastify";

export default class ErrorService {
  static handleResponse(response) {
    if (response && response.status >= 400 && response.status <= 499) {
      //Some special case according to api.
      if (response.status === 404) {
        ErrorService.sendWarnMessage(
          response.data?.message || "Something went wrong"
        );
        return false;
      }

      if (response.status === 409) {
        ErrorService.sendWarnMessage(
          response.data?.message || "Something went wrong"
        );
        return false;
      }
      ErrorService.sendErrorMessage(
        response.data?.message || "Something went wrong"
      );
      return false;
    }

    if (response.status === 500) {
      ErrorService.sendErrorMessage(
        response.data?.message || "Something went wrong"
      );
      return false;
    }

    if (
      response.status === 200 &&
      !response.data.code &&
      (response.data.status === "error" || response.data.status === "faliure")
    ) {
      ErrorService.sendErrorMessage(
        response.data?.message || "Something went wrong"
      );
      return false;
    }

    if (
      response.status === 200 &&
      (response.data.code || response.data?.status === "success")
    )
      return true;
  }

  static displayAlert(message = "Something went wrong", type) {
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  static logError(msg) {
    this.displayAlert(msg, "error");
  }

  static sendErrorMessage(msg) {
    this.displayAlert(msg, "error");
  }

  static sendWarnMessage(msg) {
    this.displayAlert(msg, "warn");
  }

  static sendOkMessage(msg) {
    this.displayAlert(msg, "success");
  }
}
