import { AppBar, Stack, Toolbar } from "@mui/material";
import moment from "moment";
import React from "react";
import { StyledText } from "../../../theme/commonStyles";

const TopBar = ({ drawerWidth }) => {
  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        backgroundColor: "#fff",
      }}
      elevation={0}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Stack direction="column">
          <StyledText
            variant="TextLeftBlackMediumCaptionLight"
            color="black-medium"
            fs={10}
          >
            {moment().format("dddd, MMMM Do YYYY")}
          </StyledText>
          <StyledText variant="TextLeftFA-BlueHighCaptionLight" fs={10}>
            {moment().format("h:mm:ss a")}
          </StyledText>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
