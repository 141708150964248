import moment from "moment";
import ErrorService from "../helpers/ErrorHandler";
import { instance } from "./axios";
import { v4 as uuidv4 } from "uuid";

export const prepareDataFormSubmit = async (formData) => {
  sessionStorage.setItem("__dataPrepare__", JSON.stringify(formData));
  try {
    const response = await instance.post("prepare-data", formData);
    if (ErrorService.handleResponse(response)) {
      ErrorService.sendOkMessage(response.data.message);
      return true;
    }
    return null;
  } catch (error) {
    if (error.name === "AxiosError")
      ErrorService.sendErrorMessage(error.message);
    else {
      ErrorService.handleResponse(error.response || error.request);
    }
  }
};

export const getDataPrepareStatus = async (body) => {
  try {
    const response = await instance.post("prepare-data-status", body);
    if (ErrorService.handleResponse(response)) {
      return response.data;
    }
    return null;
  } catch (error) {
    if (error.name === "AxiosError")
      ErrorService.sendErrorMessage(error.message);
    else {
      ErrorService.handleResponse(error.response || error.request);
    }
    return null;
  }
};

export const employeeRouteOpitimization = async (body, employeeFile) => {
  body.request_uuid = uuidv4();
  if (body.data_config?.day_start_time) {
    body.data_config.day_start_time = moment(
      body.data_config.day_start_time
    ).format("HH:mm");
  }
  body.employee_ids = body.employee_ids.map((item) => parseInt(item, 10));
  sessionStorage.setItem("__routePlan__", JSON.stringify(body));

  var form_data = new FormData();
  form_data.append("company_id", body.company_id);
  form_data.append("depth_width", body.depth_width);
  form_data.append("request_uuid", body.request_uuid);
  form_data.append("day_start_time", body.data_config.day_start_time);
  form_data.append(
    "max_distance_per_day",
    body.data_config.max_distance_per_day
  );
  form_data.append("number_of_week_days", body.data_config.number_of_week_days);
  form_data.append(
    "retailing_hours_per_day",
    body.data_config.retailing_hours_per_day
  );
  form_data.append(
    "working_hours_per_day",
    body.data_config.working_hours_per_day
  );

  if (employeeFile) form_data.append("employee_ids_file", employeeFile, "file");
  else {
    form_data.append("employee_ids", body.employee_ids);
  }

  try {
    const response = await instance.post(
      "employee-route-optimization",
      form_data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (ErrorService.handleResponse(response)) {
      ErrorService.sendOkMessage(response.data.message);
      return true;
    }
    return null;
  } catch (error) {
    if (error.name === "AxiosError")
      ErrorService.sendErrorMessage(error.message);
    else {
      ErrorService.handleResponse(error.response || error.request);
    }
    return null;
  }
};

export const getRoutePlanStatus = async (body) => {
  try {
    const response = await instance.post("route-plan-status", body);
    if (ErrorService.handleResponse(response)) {
      return response.data;
    }
    return null;
  } catch (error) {
    if (error.name === "AxiosError")
      ErrorService.sendErrorMessage(error.message);
    else {
      ErrorService.handleResponse(error.response || error.request);
    }
    return null;
  }
};
export const getRoutePlanExcel = async (body) => {
  try {
    const response = await instance.post("get-route-plan", body);
    if (ErrorService.handleResponse(response)) {
      return response.data;
    }
    return null;
  } catch (error) {
    if (error.name === "AxiosError")
      ErrorService.sendErrorMessage(error.message);
    else {
      ErrorService.handleResponse(error.response || error.request);
    }
    return null;
  }
};
export const uploadExcel = async (file) => {
  sessionStorage.setItem("__isDataUpload__", JSON.stringify(true));
  var formData = new FormData();
  formData.append("file", file);
  try {
    const response = await instance.post(
      "prepare-data-excel-upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (ErrorService.handleResponse(response)) {
      return response.data;
    }
    return null;
  } catch (error) {
    if (error.name === "AxiosError")
      ErrorService.sendErrorMessage(error.message);
    else {
      ErrorService.handleResponse(error.response || error.request);
    }
    return null;
  }
};
