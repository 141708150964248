import { flexStyles } from "../../../theme/commonStyles";

export const styles = (theme) => ({
  radioLabel: {
    flex: 1,
    "& .MuiTypography-root": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      color: theme.colors["black-high"],
    },
  },
  datePickerFieldWrapper: {
    width: "20%",
    background: theme.colors["background-dark"],
    borderRadius: "10px",
    padding: "1rem",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  textField: {
    width: "50%",
    "& .MuiFormLabel-root": {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      color: theme.colors["black-medium"],
      " .star": {
        color: theme.colors["metro-red"],
      },
    },
    "& .MuiInputBase-root": {
      "& input": {
        MozAppearance: "textfield",
        "&::-webkit-outer-spin-button,&::-webkit-inner-spin-button": {
          " -webkit-appearance": "none",
          margin: 0,
        },
      },
    },
    "& .MuiInputAdornment-root": {
      marginRight: "10px",
    },
  },
  timePickerField: {
    width: "50%",
    "& .MuiInputAdornment-root": {
      marginRight: "10px",
      background: theme.colors["background-dark"],
      "& .MuiButtonBase-root": {
        background: theme.colors["background-dark"],
      },
    },
    "& .MuiInputBase-root": {
      background: theme.colors["background-dark"],
      "&::before": {
        content: "''",
        borderBottom: "none !important",
      },
      "& input": {
        fontSize: "17px",
        background: theme.colors["background-dark"],
        color: theme.colors["primary-dark"],
        padding: "10px",
      },
    },
  },
  star: {
    color: theme.colors["metro-red"],
  },
  uploadButtonWrapper: {
    // width: "30%",
  },
  uploadButton: {
    boxShadow: "none",
    background: theme.colors["background-medium"],
    padding: "0",
    textTransform: "uppercase",
    justifyContent: "flex-start",
  },
  uploadIconWrapper: {
    ...flexStyles(),
    borderRadius: "5px",
    padding: "12px 14px",
    background: theme.colors["background-dark"],
  },
  excelIcon: {
    width: "40px",
  },
});
