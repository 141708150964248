import { Box, IconButton, Stack } from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { COLORS, StyledButton, StyledText } from "../../../theme/commonStyles";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";

const RouteHeader = ({ headerText, handler }) => {
  const nav = useNavigate();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={3}
      py={2}
    >
      <Stack direction="row" alignItems="center">
        <IconButton onClick={() => nav("/")}>
          <ArrowBackIcon sx={{ color: COLORS["black-medium"], fontSize: 28 }} />
        </IconButton>

        <Box sx={{ padding: "16px" }}>
          <StyledText variant="TextLeftBlackHighTitle-1Regular" fs={16}>
            {headerText}
          </StyledText>
        </Box>
      </Stack>
      <StyledButton bg="metro-light-green" flexcenter="true" onClick={handler}>
        <CheckIcon sx={{ color: "#fff" }} />
        <StyledText
          variant="TextLeftWhiteHighOverlineAll-CapsRegular"
          ml={1}
          fs={14}
        >
          Save
        </StyledText>
      </StyledButton>
    </Stack>
  );
};

export default RouteHeader;
