import { Toolbar } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./Sidebar";
import TopBar from "./TopBar/index";

const drawerWidth = 60;

function AppLayout() {
  return (
    <Box sx={{ display: "flex" }}>
      <TopBar drawerWidth={drawerWidth} />
      <SideBar drawerWidth={drawerWidth} />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default" }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default AppLayout;
