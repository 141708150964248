import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppLayout from "./components/layouts/AppLayout";
import Layout from "./components/layouts/Layout";
// import Home from "./components/Home/index";
import RouteOptimization from "./components/RouteOptimization";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<AppLayout />}>
          <Route
            path="/"
            element={<Navigate to="/route-optimization" replace={true} />}
          />
          <Route path="route-optimization" element={<RouteOptimization />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
