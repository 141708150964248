import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import "./fonts/TruenoLt.otf";
import "./fonts/TruenoRg.otf";
import AllContextProvider from "./global/AllContextProvider";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AllContextProvider>
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <ToastContainer
                  theme="colored"
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover={false}
                />
                <App />
              </>
            }
          />
        </Routes>
      </AllContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
