import { Backdrop, CircularProgress, IconButton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getDataPrepareStatus,
  getRoutePlanExcel,
  getRoutePlanStatus,
} from "../../../api";
import { COLORS, StyledButton, StyledText } from "../../../theme/commonStyles";
import DataPrepareForm from "./DataPrepareForm";
import RoutePlanForm from "./RoutePlanForm";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ErrorService from "../../../helpers/ErrorHandler";

const routePlanInitialStatus = {
  finished_count: null,
  in_progress_count: null,
  failed_count: null,
};

const RouteOpimizationForm = ({
  formStepValue,
  dataPrepareFormData,
  setDataPrepareFormData,
  routePlanFormData,
  setRoutePlanFormData,
  errors,
  setErrors,
  errors2,
  setErrors2,
  isUpload,
  setIsUpload,
  uploadedFile,
  setIsUploadedFile,
  employeeFile,
  setEmployeeFile,
}) => {
  const [loading, setLoading] = useState(false);
  const [dataPrepareStatus, setDataPrepareStatus] = useState(null);
  const [routePlanStatus, setRoutePlanStatus] = useState(
    routePlanInitialStatus
  );
  const dataPrepareStatusAPI = async () => {
    const data = JSON.parse(sessionStorage.getItem("__dataPrepare__"));
    const result = await getDataPrepareStatus({
      company_id: data.company_id,
    });
    if (result) {
      setDataPrepareStatus(result.data[0]);
    }
  };
  const routePlanStatusAPI = async () => {
    const data = JSON.parse(sessionStorage.getItem("__routePlan__"));
    const result = await getRoutePlanStatus({
      request_uuid: data.request_uuid,
    });
    if (result) {
      let finished_count = 0;
      let in_progress_count = 0;
      let failed_count = 0;
      result.data.forEach((item) => {
        if (item.status === "finished") finished_count++;
        if (item.status === "in_progress") in_progress_count++;
        if (item.status === "failed") failed_count++;
      });
      setRoutePlanStatus({
        finished_count,
        in_progress_count,
        failed_count,
      });
    }
  };

  const getRoutePlanAPI = async () => {
    setLoading(true);
    try {
      const data = JSON.parse(sessionStorage.getItem("__routePlan__"));
      const result = await getRoutePlanExcel({
        request_uuid: data.request_uuid,
      });
      if (result) {
        /* create a new blank workbook */
        var wb = XLSX.utils.book_new();

        const employeeIds = result.data.map((item) => item.EmployeeId);
        const uniqueEmployeeID = [...new Set(employeeIds)];

        const emp = uniqueEmployeeID.map((item) => {
          const arr = result.data.filter((dt) => dt.EmployeeId === item);
          return arr;
        });

        emp.forEach((item) => {
          /* create a worksheet for books */

          /* Add the worksheet to the workbook */
          XLSX.utils.book_append_sheet(
            wb,
            XLSX.utils.json_to_sheet(item),
            `Employee-${item[0].EmployeeId}`
          );
        });

        // /* create a worksheet for person details */
        // var wsPersonDetails = XLSX.utils.json_to_sheet(personDetails);

        // /* Add the worksheet to the workbook */
        // XLSX.utils.book_append_sheet(wb, wsPersonDetails, "PersonDetails");

        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data1 = new Blob([excelBuffer], { type: fileType });
        setLoading(false);
        FileSaver.saveAs(data1, "Employee.xlsx");
      }
    } catch (error) {
      ErrorService.sendErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  function downloadExcel() {
    getRoutePlanAPI();
  }

  useEffect(() => {
    if (formStepValue === 1) {
      setDataPrepareStatus(null);
      if (!isUpload && !uploadedFile)
        setTimeout(() => dataPrepareStatusAPI(), 3000);
    }
    if (formStepValue === 2) {
      setRoutePlanStatus(routePlanInitialStatus);
      setTimeout(() => routePlanStatusAPI(), 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formStepValue]);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress />
      </Backdrop>
      {formStepValue === 1 && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={5}
          sx={{
            borderBottom: `1px solid ${COLORS["highlight-gray-tint"]}`,
            px: 3,
            py: 2,
          }}>
          <StyledText variant="TextLeftBlackHighBody1Regular">
            {uploadedFile && isUpload ? "Data Upload" : "Data Preparation"}
          </StyledText>
          <Stack direction="row">
            <StyledButton
              stop_hover="true"
              bg="metro-dark-green"
              sx={{ px: 2 }}>
              {isUpload && uploadedFile ? (
                <StyledText
                  variant="TextLeftWhiteHighBody1Regular"
                  textTransform="capitalize">
                  Uploaded
                </StyledText>
              ) : (
                <StyledText
                  variant="TextLeftWhiteHighBody1Regular"
                  textTransform="capitalize">
                  {dataPrepareStatus ? dataPrepareStatus.status : "Fetching"}
                </StyledText>
              )}
            </StyledButton>
            {!isUpload && !uploadedFile && (
              <IconButton sx={{ ml: 1 }} onClick={dataPrepareStatusAPI}>
                <RefreshIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
      {formStepValue === 2 && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={5}
          sx={{
            borderBottom: `1px solid ${COLORS["highlight-gray-tint"]}`,
            px: 3,
            py: 2,
          }}>
          <StyledText variant="TextLeftBlackHighBody1Regular">
            Route Plan
          </StyledText>
          <Stack direction="row">
            <StyledButton
              disableRipple
              stop_hover="true"
              bg="metro-dark-green"
              sx={{ px: 2 }}>
              <StyledText variant="TextLeftWhiteHighBody1Regular">
                Finished:{" "}
                {routePlanStatus.finished_count !== null
                  ? routePlanStatus.finished_count
                  : "Fetching"}
              </StyledText>
              &nbsp; &nbsp;
              <StyledText variant="TextLeftWhiteHighBody1Regular">
                In Progress:{" "}
                {routePlanStatus.in_progress_count !== null
                  ? routePlanStatus.in_progress_count
                  : "Fetching"}
              </StyledText>{" "}
              &nbsp; &nbsp;
              <StyledText variant="TextLeftWhiteHighBody1Regular">
                Failed:{" "}
                {routePlanStatus.failed_count !== null
                  ? routePlanStatus.failed_count
                  : "Fetching"}
              </StyledText>
            </StyledButton>
            <IconButton sx={{ ml: 1 }} onClick={routePlanStatusAPI}>
              <RefreshIcon />
            </IconButton>
          </Stack>
        </Stack>
      )}

      <Stack p={3} component="form" noValidate autoComplete="off" spacing={6}>
        {formStepValue === 0 && (
          <DataPrepareForm
            dataPrepareFormData={dataPrepareFormData}
            setDataPrepareFormData={setDataPrepareFormData}
            errors={errors}
            setErrors={setErrors}
            isUpload={isUpload}
            setIsUpload={setIsUpload}
            setIsUploadedFile={setIsUploadedFile}
          />
        )}
        {formStepValue === 1 && (
          <RoutePlanForm
            routePlanFormData={routePlanFormData}
            setRoutePlanFormData={setRoutePlanFormData}
            errors2={errors2}
            setErrors2={setErrors2}
            employeeFile={employeeFile}
            setEmployeeFile={setEmployeeFile}
          />
        )}
        {formStepValue === 2 && (
          <Stack justifyContent="flex-start" alignItems="flex-start">
            <StyledButton
              bg="metro-light-green"
              sx={{ p: 1.5 }}
              disabled={routePlanStatus.finished_count < 1}
              onClick={downloadExcel}>
              <StyledText variant="TextLeftWhiteHighOverlineAll-CapsRegular">
                DOWNLOAD ROUTE PLANS
              </StyledText>
              <DownloadIcon
                sx={{
                  color: COLORS["background-light"],
                  ml: 1,
                }}
              />
            </StyledButton>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default React.memo(RouteOpimizationForm);
