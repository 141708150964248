import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import useClasses from "../../../hooks/useClasses";
import { styles } from "./sidebar.styles";
import SearchIcon from "@mui/icons-material/Search";
import { Box, ListItemButton } from "@mui/material";
import { COLORS } from "../../../theme/commonStyles";

export default function SideBar({ drawerWidth }) {
  const classes = useClasses(styles);
  return (
    <Drawer
      className={classes.drawerWrapper}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          alignItems: "center",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Box className={classes.searchButton}>
        <SearchIcon sx={{ color: COLORS["primary-fa-blue"] }} />
      </Box>
      <List sx={{ width: "100%" }}>
        {new Array(10).fill(0).map((item, i) => (
          <ListItem
            key={`item${i}`}
            className={classes.listItem}
            disablePadding
          >
            <ListItemButton sx={{ justifyContent: "center" }}>
              <Box
                className={[classes.listItemSkeleton, i === 4 && "highlighted"]}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
