import { createTheme } from "@mui/material";
import { COLORS } from "./commonStyles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#005486",
    },
  },
  typography: {
    TextLeftBlackHighBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      color: COLORS["black-high"],
    },
    TextLeftBlackMediumBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "-0.01px",
      color: COLORS["black-medium"],
    },
    "TextRightWhiteHighTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    "TextRightBlackMediumTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    "TextRightBlackLowTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    "TextRightBlackHighTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    "TextLeftWhiteHighTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["background-light"],
    },
    "TextLeftBlackMediumTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["black-medium"],
    },
    "TextLeftBlackLowTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["black-low"],
    },
    "TextLeftBlackHighTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["black-high"],
    },
    "TextCenterWhiteHighTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    "TextCenterBlackMediumTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    "TextCenterBlackLowTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    "TextCenterBlackHighTitle-1Light": {
      fontFamily: "TruenoLt",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    "TextRightWhiteHighTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    "TextRightBlackMediumTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    "TextRightBlackLowTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    "TextRightBlackHighTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    "TextLeftWhiteHighTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["background-light"],
    },
    "TextLeftFA-DarkHighTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["primary-dark"],
    },
    "TextLeftBlackMediumTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["black-medium"],
    },
    "TextLeftBlackLowTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["black-low"],
    },
    "TextLeftBlackHighTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      color: COLORS["black-high"],
    },
    "TextCenterWhiteHighTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    "TextCenterBlackMediumTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    "TextCenterBlackLowTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    "TextCenterBlackHighTitle-1Regular": {
      fontFamily: "TruenoRg",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.12px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    TextRightWhiteHighBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    TextRightBlackMediumBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    TextRightBlackLowBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    TextRightBlackHighBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    TextLeftWhiteHighBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      color: COLORS["background-light"],
    },
    TextLeftBlackMediumBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      color: COLORS["black-medium"],
    },
    TextLeftBlackLowBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      color: COLORS["black-low"],
    },
    TextLeftBlackHighBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      color: COLORS["black-high"],
    },
    TextCenterWhiteHighBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    TextCenterBlackMediumBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    TextCenterBlackLowBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    TextCenterBlackHighBody1Light: {
      fontFamily: "TruenoLt",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.5px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    TextRightWhiteHighBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    TextRightBlackMediumBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    TextRightBlackLowBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    TextRightBlackHighBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    TextLeftWhiteHighBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      color: COLORS["background-light"],
    },
    TextLeftRedHighBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      color: COLORS["metro-red"],
    },
    TextLeftBlackMediumBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      color: COLORS["black-medium"],
    },
    TextLeftBlackLowBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      color: COLORS["black-low"],
    },
    TextCenterWhiteHighBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    TextCenterBlackMediumBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    TextCenterBlackLowBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    TextCenterBlackHighBody1Regular: {
      fontFamily: "TruenoRg",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.1px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    "Transparent-Body-Light": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      color: COLORS["black-high"],
    },
    "TextStyle-OVERLINE-Light": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      color: COLORS["black-high"],
    },
    "TextRightWhiteHighOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    TextRightWhiteHighBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    "TextRightBlackMediumOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    TextRightBlackMediumBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    "TextRightBlackLowOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    TextRightBlackLowBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    "TextRightBlackHighOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    TextRightBlackHighBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    "TextLeftWhiteHighOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      color: COLORS["background-light"],
    },
    TextLeftWhiteHighBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      color: COLORS["background-light"],
    },
    TextLeftRedHighBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      color: COLORS["metro-red"],
    },
    "TextLeftFA-BlueHighBodyLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      color: COLORS["primary-fa-blue"],
    },
    "TextLeftBlackMediumOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      color: COLORS["black-medium"],
    },
    "TextLeftBlackLowOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      color: COLORS["black-low"],
    },
    TextLeftBlackLowBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      color: COLORS["black-low"],
    },
    "TextLeftBlackHighOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      color: COLORS["black-high"],
    },
    TextLeftBlackHighBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      color: COLORS["black-high"],
    },
    "TextCenterWhiteHighOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    TextCenterWhiteHighBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    "TextCenterBlackMediumOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    TextCenterBlackMediumBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    "TextCenterBlackLowOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    TextCenterBlackLowBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    "TextCenterBlackHighOverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0.5px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    TextCenterBlackHighBodyLight: {
      fontFamily: "TruenoLt",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.01px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    "Transparent-OVERLINE-Regular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      color: COLORS["black-high"],
    },
    "TextRightWhiteHighOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    TextRightWhiteHighBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    "TextRightBlackMediumOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    TextRightBlackMediumBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    "TextRightBlackLowOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    TextRightBlackLowBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    "TextRightBlackHighOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    TextRightBlackHighBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    "TextLeftWhiteHighOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      color: COLORS["background-light"],
    },
    TextLeftWhiteHighBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      color: COLORS["background-light"],
    },
    "TextLeftFA-BlueHighOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      color: COLORS["primary-fa-blue"],
    },
    "TextLeftBlackMediumOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      color: COLORS["black-medium"],
    },
    TextLeftBlackMediumBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      color: COLORS["black-medium"],
    },
    "TextLeftBlackLowOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      color: COLORS["black-low"],
    },
    TextLeftBlackLowBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      color: COLORS["black-low"],
    },
    "TextLeftBlackHighOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      color: COLORS["black-high"],
    },
    TextLeftBlackHighBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.2px",
      color: COLORS["black-medium"],
    },
    "TextCenterWhiteHighOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    TextCenterWhiteHighBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    "TextCenterFA-BlueHighOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      textAlign: "center",
      color: COLORS["primary-fa-blue"],
    },
    "TextCenterBlackMediumOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    "TextCenterBlackMediumBodyRegular-2": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    TextCenterBlackMediumBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    "TextCenterBlackLowOverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "2px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    TextCenterBlackLowBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    TextCenterBlackHighBodyRegular: {
      fontFamily: "TruenoRg",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.7px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    "Transparent-Caption": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      color: COLORS["black-high"],
    },
    "TextRightWhiteHighSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    "TextRightWhiteHighCaptionLight-2": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "right",
      color: COLORS["metro-red"],
    },
    TextRightWhiteHighCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    TextRightRedHighCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "right",
      color: COLORS["metro-red"],
    },
    "TextRightBlackMediumSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    TextRightBlackMediumCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    "TextRightBlackLowSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    TextRightBlackLowCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    "TextRightBlackHighSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    TextRightBlackHighCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    "TextLeftWhiteHighSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      color: COLORS["background-light"],
    },
    TextLeftWhiteHighCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      color: COLORS["background-light"],
    },
    TextLeftRedHighCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      color: COLORS["metro-red"],
    },
    "TextLeftFA-BlueHighCaptionLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      color: COLORS["primary-fa-blue"],
    },
    "TextLeftBlackMediumSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      color: COLORS["black-medium"],
    },
    TextLeftBlackMediumCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      color: COLORS["black-medium"],
    },
    "TextLeftBlackLowSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      color: COLORS["black-low"],
    },
    TextLeftBlackLowCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      color: COLORS["black-low"],
    },
    "TextLeftBlackHighSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      color: COLORS["black-high"],
    },
    TextLeftBlackHighCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      color: COLORS["black-high"],
    },
    "TextCenterWhiteHighSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    TextCenterWhiteHighCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    "TextCenterBlackMediumSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    TextCenterBlackMediumCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    "TextCenterBlackLowSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    TextCenterBlackLowCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    "TextCenterBlackHighSub-OverlineAll-CapsLight": {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.6,
      letterSpacing: "0.5px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    TextCenterBlackHighCaptionLight: {
      fontFamily: "TruenoLt",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "-0.05px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    "TextRightWhiteHighSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "right",
      color: COLORS["background-light"],
    },
    "TextRightBlackMediumSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "right",
      color: COLORS["black-medium"],
    },
    "TextRightBlackLowSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "right",
      color: COLORS["black-low"],
    },
    "TextRightBlackHighSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "right",
      color: COLORS["black-high"],
    },
    "TextLeftWhiteHighSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      color: COLORS["background-light"],
    },
    "TextLeftBlackMediumSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      color: COLORS["black-medium"],
    },
    "TextLeftBlackLowSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      color: COLORS["black-low"],
    },
    "TextLeftBlackHighSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      color: COLORS["black-high"],
    },
    "TextCenterWhiteHighSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "center",
      color: COLORS["background-light"],
    },
    "TextCenterRedHighSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "center",
      color: COLORS["metro-red"],
    },
    "TextCenterFA-BlueHighSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "center",
      color: COLORS["primary-fa-blue"],
    },
    "TextCenterBlackMediumSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "center",
      color: COLORS["black-medium"],
    },
    "TextCenterBlackLowSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "center",
      color: COLORS["black-low"],
    },
    "TextCenterBlackHighSub-OverlineAll-CapsRegular": {
      fontFamily: "TruenoRg",
      fontSize: "10px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.8px",
      textAlign: "center",
      color: COLORS["black-high"],
    },
    "Transparent-Caption-Number": {
      fontFamily: "TruenoLt",
      fontSize: "8px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.4px",
      color: COLORS["black-high"],
    },
  },
});
export default theme;
