export const styles = (theme) => ({
  drawerWrapper: {
    "& .MuiDrawer-paper": {
      background: theme.colors["primary-dark"],
    },
  },
  searchButton: {
    background: theme.colors["background-light"],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "7px 10px 6px 8px",
    borderRadius: "5px",
    margin: "29px 0 39px",
  },
  listItem: {
    width: "100%",
    margin: "20px 0",
    "&:first-of-type": {
      marginTop: 0,
    },
  },
  listItemSkeleton: {
    width: "16px",
    height: "16px",
    opacity: 0.38,
    background: theme.colors["background-light"],
    borderRadius: "50%",

    "&.highlighted": {
      opacity: 1,
    },
  },
});
