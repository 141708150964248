import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { StyledButton, StyledText } from "../../../theme/commonStyles";
import useClasses from "../../../hooks/useClasses";
import { styles } from "./form.styles";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import StyledRadio from "../../../global/FormComponents/StyledRadio";
import InfoIcon from "@mui/icons-material/Info";
import { ReactComponent as ExcelIcon } from "../../../assets/excel.svg";
import StyledSwitch from "../../../global/FormComponents/StyledSwitch";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const DataPrepareForm = ({
  dataPrepareFormData,
  setDataPrepareFormData,
  errors,
  setErrors,
  isUpload,
  setIsUpload,
  setIsUploadedFile,
}) => {
  const classes = useClasses(styles);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const excelDownloadHandler = () => {
    const data = [
      {
        EmployeeId: 0,
        EmployeeName: "A",
        ID: 0,
        ErpId: "A",
        ShopName: "A",
        Latitude: 27.5,
        Longitude: 73.5,
        MonthAvgNetOrderInRevenue: 0,
        company_id: 0,
        new_segmentation: "A",
        new_visitspermonth: 1,
        new_timepervisit: 15,
        BeatId: 0,
      },
    ];
    if (data.length > 0) {
      /* create a new blank workbook */
      var wb = XLSX.utils.book_new();

      /* create a worksheet for person details */
      var wsEmployeeDetails = XLSX.utils.json_to_sheet(data);

      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, wsEmployeeDetails, "Details");

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
      const data1 = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data1, "DummyInputFile.csv");
    }
  };

  const FileUploadBtn = () => {
    const fileInputRef = useRef(null);
    const handleFileUpload = async (event) => {
      setLoading(true);
      const file = event.target.files[0];
      setUploadedFile(file);
      setIsUploadedFile(file);
      setLoading(false);
    };
    return (
      <>
        <input
          ref={fileInputRef}
          onChange={handleFileUpload}
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          style={{ display: "none" }}
        />
        <Stack className={classes.uploadButtonWrapper}>
          <Stack direction="row" alignItems="center" spacing={1} mb={2}>
            <StyledText variant="TextLeftBlackHighBody1Regular">
              Upload Data
            </StyledText>
            <StyledSwitch
              checked={isUpload}
              onChange={(e) => setIsUpload(e.target.checked)}
            />
          </Stack>

          <StyledText
            variant="TextLeftBlackMediumBodyLight"
            sx={{
              opacity: !isUpload ? 0.6 : 1,
            }}>
            <span className={classes.star}>*</span>
            Upload Excel File (EmpId || StoreID)
          </StyledText>
          <StyledButton
            disabled={!isUpload}
            loading={loading}
            disableRipple
            className={classes.uploadButton}
            onClick={() => {
              fileInputRef.current.click();
            }}
            sx={{
              height: "auto !important",
              mt: 1,
            }}>
            <Box className={classes.uploadIconWrapper}>
              <ExcelIcon className={classes.excelIcon} />
            </Box>
            <StyledText
              variant="TextLeftBlackMediumOverlineAll-CapsRegular"
              fs={14}
              ml={2}>
              {uploadedFile ? `Replace File (${uploadedFile?.name})` : "Attach"}
            </StyledText>
          </StyledButton>
          <Box mt={1}>
            <Link
              component="button"
              variant="body2"
              onClick={(e) => {
                e.preventDefault();
                excelDownloadHandler();
              }}>
              Download Sample File
            </Link>
            <Tooltip
              title={`EmployeeId: (integer) - Unique id for sales rep,
              EmployeeName: (string) - Name of Employee,
              ID: (integer) - Unique Code of Store/Outlet,
              ErpId: (string) Original code for Store/Outlet,
              ShopName: (string) Name of Outlet ,
              Latitude: (float),
              Longitude: (float),
              MonthAvgNetOrderInRevenue: (float) Revenue/Target,
              company_id: (integer)- Company Code,
              new_visitspermonth: (integer) - Number of visits desired in a month,
              new_timepervisit: (integer) - Time per visit, 
              BeatId: (integer) beat ID of store/outlet (could be 0 if not available),
              new_segmentation: (string) Class/Segmentation of outlet (Sequenced alphabets like 'A', 'B'... where A is higher segment || A will be given more priority than B)`}
              placement="right-start">
              <IconButton size="small" sx={{ ml: 0.5 }}>
                <InfoIcon sx={{ fontSize: 18, color: "#005486" }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <Stack direction="column" spacing={5}>
            <Stack>
              <TextField
                sx={{
                  "& .Mui-disabled": {
                    opacity: 0.6,
                    cursor: "not-allowed",
                    pointerEvents: "auto",
                  },
                }}
                disabled={isUpload}
                type="number"
                className={classes.textField}
                label="Company Id"
                value={dataPrepareFormData.company_id}
                onChange={(e) => {
                  setDataPrepareFormData({
                    ...dataPrepareFormData,
                    company_id: +e.target.value,
                  });
                }}
                variant="standard"
                error={Boolean(errors.company_id)}
                helperText={errors.company_id}></TextField>
            </Stack>
            <Stack>
              <StyledText
                variant="TextLeftBlackHighBody1Regular"
                sx={{
                  opacity: isUpload ? 0.6 : 1,
                }}>
                *Sales Data Feed
              </StyledText>
              <FormControl sx={{ py: 1 }}>
                <RadioGroup
                  row
                  name="sales_input"
                  value={dataPrepareFormData.sales_input}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      sales_input: +e.target.value,
                      data_config: {},
                    });
                  }}>
                  <FormControlLabel
                    className={classes.radioLabel}
                    value={0}
                    control={<StyledRadio disabled={isUpload} />}
                    label="Automatic (via historical data)"
                  />
                  <FormControlLabel
                    sx={{ ml: 8 }}
                    className={classes.radioLabel}
                    value={1}
                    control={<StyledRadio disabled={isUpload} />}
                    label="Manual (bulk upload)"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack>
              <StyledText
                variant="TextLeftBlackHighBody1Regular"
                sx={{
                  opacity: isUpload ? 0.6 : 1,
                }}>
                *Segmentation Input
              </StyledText>
              <FormControl sx={{ py: 1 }}>
                <RadioGroup
                  row
                  name="segmentation_input"
                  value={dataPrepareFormData.segmentation_input}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_input: +e.target.value,
                    });
                  }}>
                  <FormControlLabel
                    className={classes.radioLabel}
                    value={0}
                    control={<StyledRadio disabled={isUpload} />}
                    label="Automatic (via sales data)"
                  />
                  <FormControlLabel
                    sx={{ ml: 8 }}
                    className={classes.radioLabel}
                    value={1}
                    control={<StyledRadio disabled={isUpload} />}
                    label="Manual (existing store segmentation)"
                  />
                </RadioGroup>
              </FormControl>
              {/* <Stack direction="row" spacing={10} mt={1}>
          <Box className={classes.datePickerFieldWrapper}>
            <DatePicker
              disabled={+dataPrepareFormData.sales_input === 1}
              className={classes.textField}
              value={dataPrepareFormData.data_config?.start_date || null}
              onChange={(newValue) => {
                setDataPrepareFormData({
                  ...dataPrepareFormData,
                  data_config: {
                    ...dataPrepareFormData.data_config,
                    start_date: newValue,
                  },
                });
              }}
              label="Start Date"
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  error={Boolean(errors.data_config.start_date)}
                  helperText={errors.data_config.start_date}
                />
              )}
            />
          </Box>
          <Box className={classes.datePickerFieldWrapper}>
            <DatePicker
              disabled={+dataPrepareFormData.sales_input === 1}
              className={classes.textField}
              value={dataPrepareFormData.data_config?.end_date || null}
              onChange={(newValue) => {
                setDataPrepareFormData({
                  ...dataPrepareFormData,
                  data_config: {
                    ...dataPrepareFormData.data_config,
                    end_date: newValue,
                  },
                });
              }}
              label="End Date"
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  {...params}
                  error={Boolean(errors.data_config.end_date)}
                  helperText={errors.data_config.end_date}
                />
              )}
            />
          </Box>
        </Stack> */}
            </Stack>
            {/* Segmentation-1 */}
            <Stack>
              <StyledText
                variant="TextLeftBlackHighBody1Regular"
                sx={{
                  opacity: isUpload ? 0.6 : 1,
                }}>
                Segmentation A
              </StyledText>
              <Stack direction="row" mt={2} spacing={10}>
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={isUpload}
                  type="number"
                  className={classes.textField}
                  label={
                    <>
                      <span className="star">*</span> Customer facing time (1-60
                      minutes)
                    </>
                  }
                  value={dataPrepareFormData.segmentation_wise_time.A || ""}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_wise_time: {
                        ...dataPrepareFormData.segmentation_wise_time,
                        A: +e.target.value === 0 ? "" : +e.target.value,
                      },
                    });
                  }}
                  variant="standard"
                  error={Boolean(errors.segmentation_wise_time.A)}
                  helperText={errors.segmentation_wise_time.A}></TextField>
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={isUpload}
                  type="number"
                  className={classes.textField}
                  label={
                    <>
                      <span className="star">*</span> Frequency (1-5)
                    </>
                  }
                  variant="standard"
                  value={dataPrepareFormData.segmentation_wise_visits.A || ""}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_wise_visits: {
                        ...dataPrepareFormData.segmentation_wise_visits,
                        A: +e.target.value === 0 ? "" : +e.target.value,
                      },
                    });
                  }}
                  error={Boolean(errors.segmentation_wise_visits.A)}
                  helperText={errors.segmentation_wise_visits.A}></TextField>
              </Stack>
            </Stack>
            {/* Segmentation-2 */}
            <Stack>
              <StyledText
                variant="TextLeftBlackHighBody1Regular"
                sx={{
                  opacity: isUpload ? 0.6 : 1,
                }}>
                Segmentation B
              </StyledText>
              <Stack direction="row" mt={2} spacing={10}>
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={isUpload}
                  type="number"
                  className={classes.textField}
                  label={
                    <>
                      <span className="star">*</span> Customer facing time (1-60
                      minutes)
                    </>
                  }
                  value={dataPrepareFormData.segmentation_wise_time.B || ""}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_wise_time: {
                        ...dataPrepareFormData.segmentation_wise_time,
                        B: +e.target.value === 0 ? "" : +e.target.value,
                      },
                    });
                  }}
                  variant="standard"
                  error={Boolean(errors.segmentation_wise_time.B)}
                  helperText={errors.segmentation_wise_time.B}></TextField>
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={isUpload}
                  type="number"
                  className={classes.textField}
                  label={
                    <>
                      <span className="star">*</span> Frequency (1-5)
                    </>
                  }
                  variant="standard"
                  value={dataPrepareFormData.segmentation_wise_visits.B || ""}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_wise_visits: {
                        ...dataPrepareFormData.segmentation_wise_visits,
                        B: +e.target.value === 0 ? "" : +e.target.value,
                      },
                    });
                  }}
                  error={Boolean(errors.segmentation_wise_visits.B)}
                  helperText={errors.segmentation_wise_visits.B}></TextField>
              </Stack>
            </Stack>
            {/* Segmentation-3 */}
            <Stack>
              <StyledText
                variant="TextLeftBlackHighBody1Regular"
                sx={{
                  opacity: isUpload ? 0.6 : 1,
                }}>
                Segmentation C
              </StyledText>
              <Stack direction="row" mt={2} spacing={10}>
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={isUpload}
                  type="number"
                  className={classes.textField}
                  label={
                    <>
                      <span className="star">*</span> Customer facing time (1-60
                      minutes)
                    </>
                  }
                  value={dataPrepareFormData.segmentation_wise_time.C || ""}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_wise_time: {
                        ...dataPrepareFormData.segmentation_wise_time,
                        C: +e.target.value === 0 ? "" : +e.target.value,
                      },
                    });
                  }}
                  variant="standard"
                  error={Boolean(errors.segmentation_wise_time.C)}
                  helperText={errors.segmentation_wise_time.C}></TextField>
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={isUpload}
                  type="number"
                  className={classes.textField}
                  label={
                    <>
                      <span className="star">*</span> Frequency (1-5)
                    </>
                  }
                  variant="standard"
                  value={dataPrepareFormData.segmentation_wise_visits.C || ""}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_wise_visits: {
                        ...dataPrepareFormData.segmentation_wise_visits,
                        C: +e.target.value === 0 ? "" : +e.target.value,
                      },
                    });
                  }}
                  error={Boolean(errors.segmentation_wise_visits.C)}
                  helperText={errors.segmentation_wise_visits.C}></TextField>
              </Stack>
            </Stack>
            {/* Segmentation-4 */}
            <Stack>
              <StyledText
                variant="TextLeftBlackHighBody1Regular"
                sx={{
                  opacity: isUpload ? 0.6 : 1,
                }}>
                Segmentation D
              </StyledText>
              <Stack direction="row" mt={2} spacing={10}>
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={isUpload}
                  type="number"
                  className={classes.textField}
                  label={<>Customer facing time (1-60 minutes) </>}
                  value={dataPrepareFormData.segmentation_wise_time?.D || ""}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_wise_time: {
                        ...dataPrepareFormData.segmentation_wise_time,
                        D: +e.target.value === 0 ? "" : +e.target.value,
                      },
                    });
                  }}
                  variant="standard"
                  error={Boolean(errors.segmentation_wise_time.D)}
                  helperText={errors.segmentation_wise_time.D}></TextField>
                <TextField
                  sx={{
                    "& .Mui-disabled": {
                      opacity: 0.6,
                      cursor: "not-allowed",
                      pointerEvents: "auto",
                    },
                  }}
                  disabled={isUpload}
                  type="number"
                  className={classes.textField}
                  label={<>Frequency (1-5)</>}
                  variant="standard"
                  value={dataPrepareFormData.segmentation_wise_visits?.D || ""}
                  onChange={(e) => {
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      segmentation_wise_visits: {
                        ...dataPrepareFormData.segmentation_wise_visits,
                        D: +e.target.value === 0 ? "" : +e.target.value,
                      },
                    });
                  }}
                  error={Boolean(errors.segmentation_wise_visits.D)}
                  helperText={errors.segmentation_wise_visits.D}></TextField>
              </Stack>
            </Stack>
            {/* Stored % to be dropped */}
            <Stack direction="row" alignItems="flex-end">
              <TextField
                sx={{
                  "& .Mui-disabled": {
                    opacity: 0.6,
                    cursor: "not-allowed",
                    pointerEvents: "auto",
                  },
                }}
                disabled={isUpload}
                type="number"
                className={classes.textField}
                label={<>Stores % to be dropped (0-30)</>}
                variant="standard"
                value={
                  dataPrepareFormData.data_config
                    ?.percentile_of_stores_to_drop || ""
                }
                onChange={(e) => {
                  if (
                    parseInt(e.target.value) > 30 ||
                    parseInt(e.target.value) < 0
                  ) {
                    setErrors({
                      ...errors,
                      data_config: {
                        ...errors.data_config,
                        percentile_of_stores_to_drop:
                          "Number must be between 0 to 30",
                      },
                    });
                  } else {
                    if (
                      errors.data_config.percentile_of_stores_to_drop !== null
                    )
                      setErrors({
                        ...errors,
                        data_config: {
                          ...errors.data_config,
                          percentile_of_stores_to_drop: null,
                        },
                      });
                    setDataPrepareFormData({
                      ...dataPrepareFormData,
                      data_config: {
                        ...dataPrepareFormData.data_config,
                        percentile_of_stores_to_drop: parseInt(e.target.value),
                      },
                    });
                  }
                }}
                error={Boolean(errors.data_config.percentile_of_stores_to_drop)}
                helperText={
                  errors.data_config.percentile_of_stores_to_drop
                }></TextField>
              <Tooltip
                title="Stores will be ranked on the basis of their revenue potential. Bottom stores i.e. those stores with less than or equal to specified percentile will; be dropped when making route plans."
                placement="right-start">
                <IconButton size="small">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <FileUploadBtn />
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(DataPrepareForm);
