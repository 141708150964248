import { Backdrop, Box, CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import {
  employeeRouteOpitimization,
  getDataPrepareStatus,
  prepareDataFormSubmit,
  uploadExcel,
} from "../../api";
import FormProgressBar from "../../global/FormProgressBar";
import ErrorService from "../../helpers/ErrorHandler";
import RouteHeader from "../layouts/RouteHeader";
import RouteOpimizationForm from "./Forms";

const steps = [
  {
    title: "Data Preparation",
    value: 0,
  },
  {
    title: "Route Plan ",
    value: 1,
  },
  {
    title: "Status and Download",
    value: 2,
  },
];

const RouteOptimization = () => {
  const [loading, setLoading] = useState(false);
  const [formStepValue, setFormStepValue] = useState(0);
  const [isUpload, setIsUpload] = useState(() => {
    const data = JSON.parse(sessionStorage.getItem("__isDataUpload__"));
    if (data) return true;
    return false;
  });
  const [uploadedFile, setIsUploadedFile] = useState(null);
  const [employeeFile, setEmployeeFile] = useState(null);
  const [dataPrepareFormData, setDataPrepareFormData] = useState(() => {
    const data = JSON.parse(sessionStorage.getItem("__dataPrepare__"));
    if (data) {
      if (data.data_config?.start_date) {
        data.data_config.start_date = moment(data.data_config.start_date);
      }
      if (data.data_config?.end_date) {
        data.data_config.end_date = moment(data.data_config.end_date);
      }

      return data;
    }
    return {
      company_id: "",
      sales_input: 0,
      segmentation_input: 0,
      segmentation_wise_time: {
        A: "",
        B: "",
        C: "",
      },
      segmentation_wise_visits: {
        A: "",
        B: "",
        C: "",
      },
      data_config: {},
    };
  });
  const [routePlanFormData, setRoutePlanFormData] = useState(() => {
    const data = JSON.parse(sessionStorage.getItem("__routePlan__"));
    if (data) {
      const time = data.data_config?.day_start_time.split(":");
      const dt = moment();
      dt.set("hour", parseInt(time[0]));
      dt.set("minute", parseInt(time[1]));
      if (data.data_config?.day_start_time) {
        data.data_config.day_start_time = dt;
      }

      return data;
    }
    const dt = moment();
    dt.set("hour", 11);
    dt.set("minute", 0);

    return {
      company_id: "",
      employee_ids: [],
      depth_width: 1,
      data_config: {
        working_hours_per_day: 8,
        retailing_hours_per_day: 6,
        number_of_week_days: 6,
        day_start_time: dt,
        max_distance_per_day: 100,
      },
    };
  });
  const [errors, setErrors] = useState({
    company_id: null,
    segmentation_wise_time: {
      A: null,
      B: null,
      C: null,
      D: null,
    },
    segmentation_wise_visits: {
      A: null,
      B: null,
      C: null,
      D: null,
    },
    data_config: {
      start_date: null,
      end_data: null,
      percentile_of_stores_to_drop: null,
    },
  });
  const [errors2, setErrors2] = useState({
    company_id: null,
    employee_ids: null,
    data_config: {
      day_start_time: null,
      max_distance_per_day: null,
      total_working_days: null,
      working_hours_per_day: null,
      number_of_week_days: null,
    },
  });

  const dataPrepareFormHandler = async () => {
    setLoading(true);
    let isValid = true;
    let err = {
      segmentation_wise_time: {
        A: null,
        B: null,
        C: null,
      },
      segmentation_wise_visits: {
        A: null,
        B: null,
        C: null,
      },
      data_config: {
        start_date: null,
        end_data: null,
        percentile_of_stores_to_drop: null,
      },
    };
    if (!dataPrepareFormData.company_id) {
      err = {
        ...dataPrepareFormData,
        company_id: "Please Enter Company Id",
      };
      isValid = false;
    }
    if (dataPrepareFormData.company_id && dataPrepareFormData.company_id < 0) {
      err = {
        ...dataPrepareFormData,
        company_id: "Negative values not allowed",
      };
      isValid = false;
    }
    // date picker errors
    if (+dataPrepareFormData.sales_input === 0) {
      // if (
      //   (dataPrepareFormData.data_config?.start_date &&
      //     dataPrepareFormData.data_config?.end_date) === undefined
      // ) {
      //   err = {
      //     ...err,
      //     data_config: {
      //       start_date: dataPrepareFormData.data_config?.start_date
      //         ? null
      //         : "Please fill start date",
      //       end_date: dataPrepareFormData.data_config?.end_date
      //         ? null
      //         : "Please fill end date",
      //     },
      //   };
      //   isValid = false;
      // }
      const endDate = parseInt(
        moment().subtract(1, "month").format("YYYYMMDD")
      );
      const startDate = parseInt(
        moment().subtract(7, "month").format("YYYYMMDD")
      );
      dataPrepareFormData.data_config.end_date = endDate;
      dataPrepareFormData.data_config.start_date = startDate;
    }
    // Visit time empty string errors
    if (
      !(
        dataPrepareFormData.segmentation_wise_time.A &&
        dataPrepareFormData.segmentation_wise_time.B &&
        dataPrepareFormData.segmentation_wise_time.C
      )
    ) {
      err = {
        ...err,
        segmentation_wise_time: {
          A:
            dataPrepareFormData.segmentation_wise_time.A === "" ||
            dataPrepareFormData.segmentation_wise_time.A === 0
              ? "Please enter Visit time"
              : null,
          B:
            dataPrepareFormData.segmentation_wise_time.B === "" ||
            dataPrepareFormData.segmentation_wise_time.B === 0
              ? "Please enter Visit time"
              : null,
          C:
            dataPrepareFormData.segmentation_wise_time.C === "" ||
            dataPrepareFormData.segmentation_wise_time.C === 0
              ? "Please enter Visit time"
              : null,
        },
      };

      isValid = false;
    }

    // Visit time limits
    if (
      dataPrepareFormData.segmentation_wise_time.A ||
      dataPrepareFormData.segmentation_wise_time.B ||
      dataPrepareFormData.segmentation_wise_time.C ||
      dataPrepareFormData.segmentation_wise_time?.D
    ) {
      if (
        dataPrepareFormData.segmentation_wise_time.A &&
        (dataPrepareFormData.segmentation_wise_time.A < 1 ||
          dataPrepareFormData.segmentation_wise_time.A > 60)
      ) {
        err.segmentation_wise_time.A =
          "Please enter the minutes in range of 1-60";
        isValid = false;
      }
      if (
        dataPrepareFormData.segmentation_wise_time.B &&
        (dataPrepareFormData.segmentation_wise_time.B < 1 ||
          dataPrepareFormData.segmentation_wise_time.B > 60)
      ) {
        err.segmentation_wise_time.B =
          "Please enter the minutes in range of 1-60";
        isValid = false;
      }
      if (
        dataPrepareFormData.segmentation_wise_time.C &&
        (dataPrepareFormData.segmentation_wise_time.C < 1 ||
          dataPrepareFormData.segmentation_wise_time.C > 60)
      ) {
        err.segmentation_wise_time.C =
          "Please enter the minutes in range of 1-60";
        isValid = false;
      }
      if (
        dataPrepareFormData.segmentation_wise_time.D &&
        (dataPrepareFormData.segmentation_wise_time.D < 1 ||
          dataPrepareFormData.segmentation_wise_time.D > 60)
      ) {
        err.segmentation_wise_time.D =
          "Please enter the minutes in range of 1-60";
        isValid = false;
      }
    }
    // Frequency count errors
    if (
      !(
        dataPrepareFormData.segmentation_wise_visits.A &&
        dataPrepareFormData.segmentation_wise_visits.B &&
        dataPrepareFormData.segmentation_wise_visits.C
      )
    ) {
      err = {
        ...err,
        segmentation_wise_visits: {
          A: !dataPrepareFormData.segmentation_wise_visits.A
            ? "Please enter Frequency"
            : null,
          B: !dataPrepareFormData.segmentation_wise_visits.B
            ? "Please enter Frequency"
            : null,
          C: !dataPrepareFormData.segmentation_wise_visits.C
            ? "Please enter Frequency"
            : null,
        },
      };
      isValid = false;
    }

    if (
      dataPrepareFormData.segmentation_wise_visits.A ||
      dataPrepareFormData.segmentation_wise_visits.B ||
      dataPrepareFormData.segmentation_wise_visits.C ||
      dataPrepareFormData.segmentation_wise_visits?.D
    ) {
      if (
        dataPrepareFormData.segmentation_wise_visits.A &&
        (dataPrepareFormData.segmentation_wise_visits.A < 1 ||
          dataPrepareFormData.segmentation_wise_visits.A > 5)
      ) {
        err.segmentation_wise_visits.A =
          "Please enter the frequencey count between 1-5";
        isValid = false;
      }
      if (
        dataPrepareFormData.segmentation_wise_visits.B &&
        (dataPrepareFormData.segmentation_wise_visits.B < 1 ||
          dataPrepareFormData.segmentation_wise_visits.B > 5)
      ) {
        err.segmentation_wise_visits.B =
          "Please enter the frequencey count between 1-5";
        isValid = false;
      }
      if (
        dataPrepareFormData.segmentation_wise_visits.C &&
        (dataPrepareFormData.segmentation_wise_visits.C < 1 ||
          dataPrepareFormData.segmentation_wise_visits.C > 5)
      ) {
        err.segmentation_wise_visits.C =
          "Please enter the frequencey count between 1-5";
        isValid = false;
      }
      if (
        dataPrepareFormData.segmentation_wise_visits.D &&
        (dataPrepareFormData.segmentation_wise_visits.D < 1 ||
          dataPrepareFormData.segmentation_wise_visits.D > 5)
      ) {
        err.segmentation_wise_visits.D =
          "Please enter the frequencey count between 1-5";
        isValid = false;
      }
    }
    if (
      dataPrepareFormData.segmentation_wise_time?.D &&
      !dataPrepareFormData.segmentation_wise_visits?.D
    ) {
      err.segmentation_wise_visits.D = "Please enter frequency";
    }

    if (
      dataPrepareFormData.segmentation_wise_visits?.D &&
      !dataPrepareFormData.segmentation_wise_time?.D
    ) {
      err.segmentation_wise_time.D = "Please enter Visit time";
    }
    setErrors(err);
    try {
      if (isValid) {
        const result = await prepareDataFormSubmit(dataPrepareFormData);
        if (result) {
          setLoading(false);
          setFormStepValue(1);
        }
      }
    } catch (error) {
      ErrorService.sendErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const dataUploadFormHandler = async () => {
    setLoading(true);
    try {
      if (!uploadedFile) {
        ErrorService.sendWarnMessage("Please upload a file to proceed");
      } else {
        const data = await uploadExcel(uploadedFile);
        if (data?.code) {
          ErrorService.sendOkMessage("File Uploaded Successfully");
        }
        setLoading(false);
        setFormStepValue(1);
      }
    } catch (error) {
      ErrorService.sendErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const routePlanFormHandler = async () => {
    setLoading(true);
    let isValid = true;
    let err = {
      company_id: null,
      employee_ids: null,
      data_config: {
        day_start_time: null,
        max_distance_per_day: null,
        total_working_days: null,
        working_hours_per_day: null,
        number_of_week_days: null,
      },
    };
    if (!routePlanFormData.company_id) {
      err = {
        ...err,
        company_id: "Please Enter Company Id",
      };
      isValid = false;
    }
    if (routePlanFormData.company_id && routePlanFormData.company_id < 0) {
      err = {
        ...err,
        company_id: "Negative values not allowed",
      };
      isValid = false;
    }
    // if (!employeeFile && routePlanFormData.employee_ids.length < 1) {
    //   err = {
    //     ...err,
    //     employee_ids: "Please Enter at least 1 employee id",
    //   };
    //   isValid = false;
    // }
    if (!employeeFile) {
      err = {
        ...err,
        employee_ids: "Please Upload Employee Details file",
      };
      isValid = false;
    }

    if (
      routePlanFormData.data_config?.max_distance_per_day &&
      routePlanFormData.data_config.max_distance_per_day < 0
    ) {
      err = {
        ...err,
        data_config: {
          max_distance_per_day: "Negative values not allowed",
        },
      };
      isValid = false;
    }
    setErrors2(err);
    try {
      if (isValid) {
        if (!isUpload && !uploadedFile) {
          const data = JSON.parse(sessionStorage.getItem("__dataPrepare__"));
          const dataPrepareStatus = await getDataPrepareStatus({
            company_id: data.company_id,
          });
          if (
            dataPrepareStatus &&
            dataPrepareStatus.data[0].status === "in_progress"
          ) {
            ErrorService.sendWarnMessage("Please Wait for Data Prepare");
          } else {
            const result = await employeeRouteOpitimization(
              routePlanFormData,
              employeeFile
            );
            if (result) {
              setLoading(false);
              setFormStepValue(2);
            }
          }
        } else {
          const result = await employeeRouteOpitimization(
            routePlanFormData,
            employeeFile
          );
          if (result) {
            setLoading(false);
            setFormStepValue(2);
          }
        }
      }
    } catch (error) {
      ErrorService.sendErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  const headerButtonHandler = () => {
    switch (formStepValue) {
      case 0:
        return isUpload ? dataUploadFormHandler() : dataPrepareFormHandler();
      case 1:
        return routePlanFormHandler();
      default:
        return () => {};
    }
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}>
        <CircularProgress />
      </Backdrop>
      <RouteHeader
        headerText={"Route Optimization"}
        handler={headerButtonHandler}
      />
      <FormProgressBar
        steps={steps}
        currentValue={formStepValue}
        setValue={setFormStepValue}
      />
      <RouteOpimizationForm
        formStepValue={formStepValue}
        dataPrepareFormData={dataPrepareFormData}
        setDataPrepareFormData={setDataPrepareFormData}
        routePlanFormData={routePlanFormData}
        setRoutePlanFormData={setRoutePlanFormData}
        errors={errors}
        setErrors={setErrors}
        errors2={errors2}
        setErrors2={setErrors2}
        isUpload={isUpload}
        setIsUpload={setIsUpload}
        uploadedFile={uploadedFile}
        setIsUploadedFile={setIsUploadedFile}
        employeeFile={employeeFile}
        setEmployeeFile={setEmployeeFile}
      />
    </Box>
  );
};

export default RouteOptimization;
